<template>
  <div class="ui-page">
    <div class="container">
      <div class="ui-title">
        Typography
      </div>
      <div class="d-flex pb-20">
        <div class="ui-block">
          <div class="text-h1">Heading 1 (text-h1)</div>
          <div class="my-10"></div>
          <div class="text-h2">Heading 2 (text-h2)</div>
          <div class="my-10"></div>
          <div class="text-h3">Heading 3 (text-h3)</div>
          <div class="my-10"></div>
          <div class="text-h4 color-link">Heading 4 (text-h4 color-link)</div>
          <div class="my-10"></div>
          <div class="text-h5">Heading 5 (text-h5)</div>
          <div class="my-10"></div>
          <div class="text-h6">Heading 6 (text-h6)</div>
          <div class="my-10"></div>
          <div class="text-subheading">Subheading (text-subheading)</div>
          <hr class="my-20" />
          <a href="" class="text-link">Link (text-link)</a>
          <div class="text-base">Base text (text-base)</div>
          <div class="my-10"></div>
          <div class="menu-title">Menu title (menu-title)</div>
          <div class="menu-text">Menu title (menu-text)</div>
          <div class="my-10"></div>
          <div class="text-caption">Caption (text-caption)</div>
          <div class="text-caption-small">Caption small (text-caption-small)</div>
        </div>
        <div class="ui-block">
          Default color: .color-text-darker <br>
          If you need another color from UI you can add this class (example: <span class="color-text-darker">h4-like color-link</span>)
        </div>
      </div>

      <div class="ui-title">
        Component SidebarCaseTitle
      </div>
      <div class="d-flex pb-20">
        <div class="ui-block">
          <SidebarMenuTitle
            title="Profile search"
            active-route="/"
            icon="user-search"
            :show-plus="true"
            :test-info="{
              testTitle: '',
              testPlus: ''
            }"
            @plusAction="mockAction"
          />
          <SidebarMenuTitle
            title="Connection search"
            active-route="/"
            icon="hierarchy"
            :show-plus="true"
            :test-info="{
              testTitle: '',
              testPlus: ''
            }"
            @plusAction="mockAction"
          />
          <SidebarMenuTitle
            title="Saved in tags"
            active-route="/"
            icon="archive-tick"
            :showPlus="true"
            :test-info="{
              testTitle: '',
              testPlus: ''
            }"
            @plusAction="mockAction"
          />
          <SidebarMenuTitle
            title="Report"
            active-route="/"
            icon="document-text"
            :show-plus="true"
            :test-info="{
              testTitle: '',
              testPlus: ''
            }"
            @plusAction="mockAction"
          />
          <SidebarMenuTitle
            title="Cases"
            active-route="/"
            icon="briefcase"
            :show-plus="true"
            :test-info="{
              testTitle: '',
              testPlus: ''
            }"
            @plusAction="mockAction"
          />
          <SidebarMenuTitle
            title="Monitoring"
            active-route="/"
            icon="activity"
            :show-plus="true"
            :beta="true"
            :test-info="{
              testTitle: '',
              testPlus: ''
            }"
            @plusAction="mockAction"
          />

          <br>
          <hr>
          <br>

          <SidebarMenuTitle
            :title="'Profile search'"
            :active-route="'/ui'"
            :icon="'user-search'"
            :showPlus="true"
            :test-info="{
              testTitle: '',
              testPlus: ''
            }"
            @plusAction="mockAction"
          />
          <SidebarMenuTitle
            :title="'Connection search'"
            :active-route="'/ui'"
            :icon="'hierarchy'"
            :showPlus="true"
            :test-info="{
              testTitle: '',
              testPlus: ''
            }"
            @plusAction="mockAction"
          />
          <SidebarMenuTitle
            :title="'Saved in tags'"
            :active-route="'/ui'"
            :icon="'archive-tick'"
            :showPlus="true"
            :test-info="{
              testTitle: '',
              testPlus: ''
            }"
            @plusAction="mockAction"
          />
          <SidebarMenuTitle
            :title="'Report'"
            :active-route="'/ui'"
            :icon="'document-text'"
            :showPlus="true"
            :test-info="{
              testTitle: '',
              testPlus: ''
            }"
            @plusAction="mockAction"
          />
          <SidebarMenuTitle
            :title="'Cases'"
            :active-route="'/ui'"
            :icon="'briefcase'"
            :showPlus="true"
            :test-info="{
              testTitle: '',
              testPlus: ''
            }"
            @plusAction="mockAction"
          />
          <SidebarMenuTitle
            :title="'Monitoring'"
            :active-route="'/ui'"
            :icon="'activity'"
            :showPlus="true"
            :beta="true"
            :test-info="{
              testTitle: '',
              testPlus: ''
            }"
            @plusAction="mockAction"
          />
        </div>
        <div class="ui-block d-grid gtc-3">
          <SidebarMenuTitle
            title="Profile search"
            active-route="/"
            icon="user-search"
            :show-plus="true"
            :test-info="{
              testTitle: '',
              testPlus: ''
            }"
            @plusAction="mockAction"
            :mobile="true"
          />
          <SidebarMenuTitle
            title="Connection search"
            active-route="/"
            icon="hierarchy"
            :show-plus="true"
            :test-info="{
              testTitle: '',
              testPlus: ''
            }"
            @plusAction="mockAction"
            :mobile="true"
          />
          <SidebarMenuTitle
            title="Saved in tags"
            active-route="/"
            icon="archive-tick"
            :showPlus="true"
            :test-info="{
              testTitle: '',
              testPlus: ''
            }"
            @plusAction="mockAction"
            :mobile="true"
          />
          <SidebarMenuTitle
            title="Report"
            active-route="/"
            icon="document-text"
            :show-plus="true"
            :test-info="{
              testTitle: '',
              testPlus: ''
            }"
            @plusAction="mockAction"
            :mobile="true"
          />
          <SidebarMenuTitle
            title="Cases"
            active-route="/"
            icon="briefcase"
            :show-plus="true"
            :test-info="{
              testTitle: '',
              testPlus: ''
            }"
            @plusAction="mockAction"
            :mobile="true"
          />
          <SidebarMenuTitle
            title="Monitoring"
            active-route="/"
            icon="activity"
            :show-plus="true"
            :beta="true"
            :test-info="{
              testTitle: '',
              testPlus: ''
            }"
            @plusAction="mockAction"
            :mobile="true"
          />
          <SidebarMenuTitle
            :title="'Profile search'"
            :active-route="'/ui'"
            :icon="'user-search'"
            :showPlus="true"
            :test-info="{
              testTitle: '',
              testPlus: ''
            }"
            @plusAction="mockAction"
            :mobile="true"
          />
          <SidebarMenuTitle
            :title="'Connection search'"
            :active-route="'/ui'"
            :icon="'hierarchy'"
            :showPlus="true"
            :test-info="{
              testTitle: '',
              testPlus: ''
            }"
            @plusAction="mockAction"
            :mobile="true"
          />
          <SidebarMenuTitle
            :title="'Saved in tags'"
            :active-route="'/ui'"
            :icon="'archive-tick'"
            :showPlus="true"
            :test-info="{
              testTitle: '',
              testPlus: ''
            }"
            @plusAction="mockAction"
            :mobile="true"
          />
          <SidebarMenuTitle
            :title="'Report'"
            :active-route="'/ui'"
            :icon="'document-text'"
            :showPlus="true"
            :test-info="{
              testTitle: '',
              testPlus: ''
            }"
            @plusAction="mockAction"
            :mobile="true"
          />
          <SidebarMenuTitle
            :title="'Cases'"
            :active-route="'/ui'"
            :icon="'briefcase'"
            :showPlus="true"
            :test-info="{
              testTitle: '',
              testPlus: ''
            }"
            @plusAction="mockAction"
            :mobile="true"
          />
          <SidebarMenuTitle
            :title="'Monitoring'"
            :active-route="'/ui'"
            :icon="'activity'"
            :showPlus="true"
            :beta="true"
            :test-info="{
              testTitle: '',
              testPlus: ''
            }"
            @plusAction="mockAction"
            :mobile="true"
          />
        </div>
      </div>
      <div class="ui-title">
        Inputs & Buttons
      </div>
      <div class="d-flex">
        <div class="ui-block">
          <label for="" class="label">Basic Label</label>
          <input type="text" class="input" placeholder="Basic Input" />
          <div class="input-error">Invalid Email, please use valid email (example@domain.com)</div>
          <br>
          <br>
          <label for="" class="label">Basic Label</label>
          <input type="text" class="input bg-blue" placeholder="Basic Input Blue" />
          <br>
          <br>
          <label for="" class="label">
            <span>Label</span>
            <span class="color-warning">With warning</span>
          </label>
          <input type="text" class="input" placeholder="Basic Input" />
        </div>
        <div class="ui-block">
          <button class="bttn-primary">Basic Button (bttn-primary)</button>
          <br>
          <button class="bttn-primary w-100">Button Fullwidth (bttn-primary w-100)</button>
          <br>
          <button class="bttn-white">Button white + border-radius (bttn-white br-30)</button>
          <br>
          <button
            class="bttn-search"
            :class="{ 'is-active': bttnSearch }"
            @click="bttnSearch = !bttnSearch"
          >
            <Icon :name="bttnSearch ? 'check-new' : 'plus-new'" />
            Search button (bttn-search)
          </button>
        </div>
      </div>
      <div class="d-flex">
        <div class="ui-block">
          <Dropdown v-model="typeToPrint" :options="types" optionLabel="name" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SidebarMenuTitle from '@/components/sidebar/MenuTitle';
import Dropdown from 'primevue/dropdown';
import Icon from '@/components/app/Icon';

export default {
  components: {
    SidebarMenuTitle,
    Dropdown,
    Icon
  },
  data () {
    return {
      typeToPrint: { name: 'Export to .PDF', code: 'pdf' },
      types: [
        { name: 'Export to .PDF', code: 'pdf' },
        { name: 'Export to .XLSX', code: 'xls' },
        { name: 'Export to .HTML', code: 'html' }
      ],
      bttnSearch: false
    };
  },
  methods: {
    mockAction () {
      alert('Action!');
    }
  }
};
</script>

<style lang="scss" scoped>
  .ui-page {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    background: #fff;
    padding: 50px 0;
  }
  .container {
    max-width: 1200px;
    width: 100%;
    margin: auto;
  }
  .ui-title {
    font-size: 18px;
    font-weight: 600;
    color: #000;
    margin-bottom: 20px;
  }
  .ui-block {
    border: 1px dashed #7B61FF;
    border-radius: 5px;
    width: 400px;
    padding: 20px;
    margin-bottom: 40px;

    + .ui-block {
      margin-left: 20px;
    }
  }
  .p-dropdown {
    width: 13rem;
  }
  .p-dropdown-panel {
    z-index: 100000 !important;
  }
</style>
